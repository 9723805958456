@charset "UTF-8";
@import "core/brackpoint";

//// TOP //////////////////////////////
#main-contents header .top_header .sp .bx-wrapper .bx-viewport {
  height: 120vw !important;
}

#main-contents header .top_header .sp .bx-wrapper .bx-viewport .bxslider li {
  height: 115vw;
}

.sTitle {
  margin: 15px auto 30px auto;
  width: 90%;
  //  color:#2A4691;

  font-weight: bold;

  @media only screen and (min-width: 641px) {
    margin: 50px auto 15px auto;
    font-size: 24px;
  }

  img {
    margin: 0 auto;
    margin-top: 20px;
    display: block;
    width: 85%;
    height: auto;

    @media only screen and (min-width: 641px) {
      margin-top: 40px;
      width: 420px;
    }
  }
}

#important {
  .fair_news {
    .act {
      padding: 16px 0;

      span {
        margin-top: 5px;
      }

      @media only screen and (max-width: 640px) {
        padding: 12px 0;
      }
    }
  }
}

.sectionBtn {
  margin-top: 45px;

  @media only screen and (max-width: 640px) {
    margin-top: 8%;
  }

  a {
    margin: 0 auto;
    padding: 1.2rem 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    max-width: 400px;
    font-weight: bold;
    font-size: 16px;
    border: 1px solid #ddd;

    &::before {
      margin-right: 10px;
      content: "▶︎";
      display: inline-block;
    }

    @media only screen and (max-width: 640px) {
      margin-top: 0;
      padding: 0.8rem;
      font-size: 14px;
      border-color: #212121;
    }
  }

  &.gBtn {
    margin-top: 0;

    @media only screen and (max-width: 640px) {
      margin-top: 8%;
    }

    a {
      padding: 0.8rem 0;
      width: 100%;
      max-width: none;
      color: #AD9268;
      border-color: #AD9268;
      background-color: transparent;
    }
  }
}

#chapel,
#party,
#ocean,
#cuisine {
  .bg_wrap {
    +.sectionBtn {
      @media only screen and (max-width: 640px) {
        margin-top: -3%;
      }
    }
  }
}

.party_slider {
  .slick-track {
    @media only screen and (max-width: 640px) {
      height: 18.5vh !important;
    }
  }

  +.sectionBtn {
    @media only screen and (max-width: 640px) {
      margin-top: 2.5%;
    }
  }
}

main {
  section {
    &.sec8:not(._fuwel) {
      .content {
        iframe {
          @media only screen and (max-width: 640px) {
            height: 290vw;
          }
        }
      }
    }
  }
}

.mkvbanner {
  position: absolute;
  z-index: 9999;
  top: auto;
  bottom: 3.5vw;
  right: 3.5vw;
}

.mkvbanner img {
  width: 16vw;
  max-width: 280px;
}

@media screen and (max-width:1119px) {
  .mkvbanner {
    width: auto;
  }

  .mkvbanner img {
    width: 23vw;
    max-width: none;
  }
}

@media screen and (max-width:640px) {
  .mkvbanner {
    z-index: 9998;
    top: 3.5vw;
    bottom: auto;
    width: 40%;
  }

  .mkvbanner img {
    width: 100%;
    max-width: none;
  }
}

@media only screen and (max-width: 1119px) {
  .drawer_menu .content .flexwrap a:first-child {
    background-color: #D3E6F6;
  }

  .drawer_menu .content .flexwrap a:nth-child(2),
  .drawer_menu .content .flexwrap a:nth-child(3) {
    background-color: #fff;
  }

  .drawer_menu .content .flexwrap a:nth-child(10) {
    background-color: #FFF;
    border-top: none;
  }

  .drawer_menu .content .flexwrap a:nth-child(11) {
    background-color: #FFF;
  }
}

#bnrfix {
  display: none !important;

  @media only screen and (max-width: 640px) {
    display: none !important;
  }
}

/* 20210720 add */
main {
  .sec10 {
    .content {
      .party_slider {
        .slick-track {
          @media only screen and (max-width: 640px) {
            height: auto !important;
          }

          .slick-slide {
            .title {
              @media only screen and (max-width: 640px) {
                // font-size: 2.4vw;
              }
            }
          }
        }
      }

      .sectionBtn {
        margin-top: 15px;

        @media only screen and (max-width: 640px) {}
      }
    }
  }
}


//2021.10.15 add 課題156
.banner-covid19 {
  text-align: center;
  max-width: 1000px;
  width: 90%;
  margin: 0 auto;

  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}

.safety-wedding {
  margin: 4em auto 6em auto;

  .safety-wedding-inner {
    padding-bottom: 4em;
    background-color: #D3E6F5;

    @include mq-sp {
      margin-top: -1em;
      padding-top: 2em;
    }

    ;

    @include mq-pc-tb {
      padding-top: 4em;
    }

    ;
  }

  * {
    box-sizing: border-box;
  }

  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }

  h1 {
    font-weight: bold;
    color: #2A4691;
    text-align: center;

    @include mq-sp {
      font-size: 20px;
    }

    ;

    @include mq-pc-tb {
      font-size: 28px;
    }

    ;
  }

  .lead {
    margin-top: 1.5em;
    margin-bottom: 2em;
    line-height: 2;
    text-align: center;
    font-size: 14px;
  }

  .safety-box-wrap {
    margin: 0 auto;
    width: 90%;

    @include mq-pc-tb {
      max-width: 1197px;
      display: flex;
      justify-content: space-between;

      .safety-box {
        max-width: 383px;
        width: calc((1197px - 44px) / 3);
      }
    }

    ;

    .safety-box {
      &+.safety-box {
        @include mq-sp {
          margin-top: 2em;
        }

        ;

        @include mq-pc-tb {
          margin-left: 22px;
        }

        ;
      }

      figure {
        max-width: 346px;
        width: calc((346 / 383) * 100%);
        margin: 0 auto;
      }

      .text-area {
        margin-top: -2em;
        padding: 0 1.5em 1.5em 1.5em;
        background-color: #F6FAFD;

        h2 {
          padding-top: 3em;
          font-weight: bold;
          color: #2A4691;

          @include mq-sp {
            font-size: 16px;
          }

          ;

          @include mq-pc-tb {
            font-size: 20px;
          }

          ;
        }

        ;

        p {
          margin-top: 1em;
          line-height: 2;
          text-align: left;
        }
      }
    }
  }

  .mataniti-wedding {
    max-width: 980px;
    width: 90%;
    border: double 4px #AE9269;
    margin: 4em auto 0 auto;
    padding: 2em 1em;

    @include mq-sp {
      font-size: 14px;
    }

    ;

    .text {
      line-height: 2;

      @include mq-sp {
        font-size: 14px;
      }

      ;

      @include mq-pc-tb {
        font-size: 16px;
      }

      ;
    }

    .button {
      margin-top: 1.5em;

      a {
        position: relative;
        display: inline-block;
        padding: 1.25em 2em;
        background-color: #AE9269;
        line-height: 1.4;
        text-align: center;
        text-decoration: none;
        color: #fff;

        @include mq-sp {
          font-size: 15px;
        }

        ;

        @include mq-pc-tb {
          font-size: 17px;
        }

        ;

        i {
          display: inline-block;
          width: 25px;
          height: 5px;
          margin-bottom: 0.25em;
          margin-left: 1em;
          border-bottom: 1px solid #fff;
          border-right: 2px solid #fff;
          transform: skew(45deg);
        }
      }
    }
  }
}

._bnrArea {
  padding: 0px 0 55px;

  @include mq-sp {
    padding: 12% 0 12%;
  }

  ;

  ul {
    margin: 0 auto;
    width: 90%;
    max-width: 910px;

    li {
      margin-bottom: 35px;

      @include mq-sp {
        margin-bottom: 3%;
      }

      ;

      a {
        display: block;

        img {
          width: auto;
          height: 215px;

          @include mq-sp {
            width: 100%;
            height: auto;
          }

          &.sp-non {
            @include mq-sp {
              display: none;
            }

            ;
          }

          &.pc-non {
            @include mq-pc-tb {
              display: none;
            }

            ;
          }
        }
      }
    }
  }
}

.mvWrap {
  position: relative;

  @media screen and (max-width:1280px) and (max-height:640px) {
    .mkvbanner {
      bottom: calc(3.5vw + 100px);
    }
  }
}



//2022.07.26 add 課題212
footer .nav_wrap nav.global a {
  padding: 0 20px !important;
}


//2022.09.06 add 課題208
main section.sec11 .content .flexwrap {
  flex-wrap: wrap !important;
  justify-content: center !important;
  gap: calc((1100px - 530px - 530px) / 2);

  @include mq-sp {

    //display: flex;
    a.box {
      width: 47% !important;
    }
  }

  ;
}

/* 20220913 add task214 */
.renewalTopic {
  margin: 5em 0;
  padding: 5em 0;
  background-color: #FCF3D6;

  @include mq-sp {
    margin: 3em 0;
    padding: 3em 0;
  }

  figure {
    margin: 0;
  }

  .inner {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1200px;

    @include mq-sp {
      max-width: none;
      width: 100%;
      flex-direction: column-reverse;
    }
  }

  .imgBox {
    position: relative;
    z-index: 5;
    margin-right: -3%;
    flex-shrink: 0;
    width: 60%;

    @include mq-sp {
      z-index: auto;
      margin-right: 0;
      width: 90%;
    }

    img {
      display: block;
      width: 100%;
    }

    span {
      position: absolute;
      bottom: 5px;
      right: 5px;
      font-weight: bold;
      text-shadow:
        1px 0 0 white,
        0 1px 0 white,
        -1px 0 0 white,
        0 -1px 0 white
    }
  }

  .textBox {
    position: relative;
    padding: 5.5vw 2vw 5.5vw 3vw;
    width: 40%;
    background-color: #fff;

    @include mq-sp {
      padding: 3em;
      width: 60%;
    }

    .enTitle {
      position: absolute;
      top: -8%;
      right: -13%;
      margin: 0;
      width: 80%;

      @include mq-sp {
        width: 80%;
      }

      img {
        display: block;
        width: 100%;
      }
    }

    .sectionTitle {
      img {
        margin: 0 auto;
        display: block;
        width: 70%;
        max-width: 355px;

        @include mq-sp {
          width: 100%;
        }
      }
    }

    h2 {
      margin-top: 1.5vw;
      font-size: 20px;
      font-weight: bold;

      @include mq-sp {
        margin-top: 1.5em;
      }
    }

    p {
      margin: 1.5vw 0;

      @include mq-sp {
        display: none;
      }
    }

    a {
      padding: 1vw 3.5vw;
      display: inline-block;
      text-align: center;
      color: #666;
      border: 1px solid #666;

      @include mq-sp {
        margin-top: 1.5em;
        padding: 1em 0;
        width: 100%;
        width: 100%;
      }
    }
  }
}

//2022.11.07 add 課題223
.mkvbanner {
  &._sp {
    &._bottom {
      @include mq-sp {
        top: auto;
        bottom: 30vw;
      }
    }
  }
}

#main-contents header .top_header .sp .bx-wrapper .bx-viewport .bxslider li .mkvbanner._sp img {
  @include mq-sp {
    width: 100%;
    height: auto;
  }
}

.renewalTopic .textBox._ttl2 {
  @include mq-sp {
    padding: 2.5em;
  }

  .enTitle {
    position: static;
    margin: 0 auto;
    width: 70%;

    @include mq-sp {
      width: 100%;
    }
  }

  h2 {
    color: #2a4691;
    margin-top: 0.5vw;
    font-weight: bold;

    @include mq-sp {
      margin-top: 1.5vw;
      font-size: 4vw;
    }
  }

  p {
    margin-bottom: 0;

    @include mq-sp {
      margin-top: 6vw;
      display: block;
      font-size: 14px;
    }
  }
}

main section.sec1 .bg_wrap._concept2 {
  background-image: url(../../img/top/bg_sec1_2.jpg);
  height: 750px;

  @include mq-sp {
    background-image: url(../../img/top/bg_sec1_2_sp.jpg);
    height: 80vh;
  }

  .textwrap {
    @include mq-sp {
      padding-top: 5vh;
    }

    p {
      line-height: 1.7;
      text-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    }
  }
}

//20230912 add 課題279
main section.sec10 .content .party_slider .slider p.title {
  @include mq-sp {
    text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    /* Safari */
    font-size: 14px !important;
  }
}


.ppBnr {
  text-align: center;
  a {
    display: inline-block;
    max-width: 900px;
    width: 90%;
    img {
      max-width: 100%;
    }
  }
}