@charset "UTF-8";
#main-contents header .top_header .sp .bx-wrapper .bx-viewport {
  height: 120vw !important;
}

#main-contents header .top_header .sp .bx-wrapper .bx-viewport .bxslider li {
  height: 115vw;
}

.sTitle {
  margin: 15px auto 30px auto;
  width: 90%;
  font-weight: bold;
}

@media only screen and (min-width: 641px) {
  .sTitle {
    margin: 50px auto 15px auto;
    font-size: 24px;
  }
}

.sTitle img {
  margin: 0 auto;
  margin-top: 20px;
  display: block;
  width: 85%;
  height: auto;
}

@media only screen and (min-width: 641px) {
  .sTitle img {
    margin-top: 40px;
    width: 420px;
  }
}

#important .fair_news .act {
  padding: 16px 0;
}

#important .fair_news .act span {
  margin-top: 5px;
}

@media only screen and (max-width: 640px) {
  #important .fair_news .act {
    padding: 12px 0;
  }
}

.sectionBtn {
  margin-top: 45px;
}

@media only screen and (max-width: 640px) {
  .sectionBtn {
    margin-top: 8%;
  }
}

.sectionBtn a {
  margin: 0 auto;
  padding: 1.2rem 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  max-width: 400px;
  font-weight: bold;
  font-size: 16px;
  border: 1px solid #ddd;
}

.sectionBtn a::before {
  margin-right: 10px;
  content: "▶︎";
  display: inline-block;
}

@media only screen and (max-width: 640px) {
  .sectionBtn a {
    margin-top: 0;
    padding: 0.8rem;
    font-size: 14px;
    border-color: #212121;
  }
}

.sectionBtn.gBtn {
  margin-top: 0;
}

@media only screen and (max-width: 640px) {
  .sectionBtn.gBtn {
    margin-top: 8%;
  }
}

.sectionBtn.gBtn a {
  padding: 0.8rem 0;
  width: 100%;
  max-width: none;
  color: #AD9268;
  border-color: #AD9268;
  background-color: transparent;
}

@media only screen and (max-width: 640px) {
  #chapel .bg_wrap + .sectionBtn,
  #party .bg_wrap + .sectionBtn,
  #ocean .bg_wrap + .sectionBtn,
  #cuisine .bg_wrap + .sectionBtn {
    margin-top: -3%;
  }
}

@media only screen and (max-width: 640px) {
  .party_slider .slick-track {
    height: 18.5vh !important;
  }
}

@media only screen and (max-width: 640px) {
  .party_slider + .sectionBtn {
    margin-top: 2.5%;
  }
}

@media only screen and (max-width: 640px) {
  main section.sec8:not(._fuwel) .content iframe {
    height: 290vw;
  }
}

.mkvbanner {
  position: absolute;
  z-index: 9999;
  top: auto;
  bottom: 3.5vw;
  right: 3.5vw;
}

.mkvbanner img {
  width: 16vw;
  max-width: 280px;
}

@media screen and (max-width: 1119px) {
  .mkvbanner {
    width: auto;
  }
  .mkvbanner img {
    width: 23vw;
    max-width: none;
  }
}

@media screen and (max-width: 640px) {
  .mkvbanner {
    z-index: 9998;
    top: 3.5vw;
    bottom: auto;
    width: 40%;
  }
  .mkvbanner img {
    width: 100%;
    max-width: none;
  }
}

@media only screen and (max-width: 1119px) {
  .drawer_menu .content .flexwrap a:first-child {
    background-color: #D3E6F6;
  }
  .drawer_menu .content .flexwrap a:nth-child(2),
  .drawer_menu .content .flexwrap a:nth-child(3) {
    background-color: #fff;
  }
  .drawer_menu .content .flexwrap a:nth-child(10) {
    background-color: #FFF;
    border-top: none;
  }
  .drawer_menu .content .flexwrap a:nth-child(11) {
    background-color: #FFF;
  }
}

#bnrfix {
  display: none !important;
}

@media only screen and (max-width: 640px) {
  #bnrfix {
    display: none !important;
  }
}

/* 20210720 add */
@media only screen and (max-width: 640px) {
  main .sec10 .content .party_slider .slick-track {
    height: auto !important;
  }
}

main .sec10 .content .sectionBtn {
  margin-top: 15px;
}

.banner-covid19 {
  text-align: center;
  max-width: 1000px;
  width: 90%;
  margin: 0 auto;
}

.banner-covid19 img {
  max-width: 100%;
  width: 100%;
  height: auto;
}

.safety-wedding {
  margin: 4em auto 6em auto;
}

.safety-wedding .safety-wedding-inner {
  padding-bottom: 4em;
  background-color: #D3E6F5;
}

@media screen and (max-width: 640px) {
  .safety-wedding .safety-wedding-inner {
    margin-top: -1em;
    padding-top: 2em;
  }
}

@media screen and (min-width: 639px) {
  .safety-wedding .safety-wedding-inner {
    padding-top: 4em;
  }
}

.safety-wedding * {
  box-sizing: border-box;
}

.safety-wedding img {
  max-width: 100%;
  width: 100%;
  height: auto;
}

.safety-wedding h1 {
  font-weight: bold;
  color: #2A4691;
  text-align: center;
}

@media screen and (max-width: 640px) {
  .safety-wedding h1 {
    font-size: 20px;
  }
}

@media screen and (min-width: 639px) {
  .safety-wedding h1 {
    font-size: 28px;
  }
}

.safety-wedding .lead {
  margin-top: 1.5em;
  margin-bottom: 2em;
  line-height: 2;
  text-align: center;
  font-size: 14px;
}

.safety-wedding .safety-box-wrap {
  margin: 0 auto;
  width: 90%;
}

@media screen and (min-width: 639px) {
  .safety-wedding .safety-box-wrap {
    max-width: 1197px;
    display: flex;
    justify-content: space-between;
  }
  .safety-wedding .safety-box-wrap .safety-box {
    max-width: 383px;
    width: calc((1197px - 44px) / 3);
  }
}

@media screen and (max-width: 640px) {
  .safety-wedding .safety-box-wrap .safety-box + .safety-box {
    margin-top: 2em;
  }
}

@media screen and (min-width: 639px) {
  .safety-wedding .safety-box-wrap .safety-box + .safety-box {
    margin-left: 22px;
  }
}

.safety-wedding .safety-box-wrap .safety-box figure {
  max-width: 346px;
  width: calc((346 / 383) * 100%);
  margin: 0 auto;
}

.safety-wedding .safety-box-wrap .safety-box .text-area {
  margin-top: -2em;
  padding: 0 1.5em 1.5em 1.5em;
  background-color: #F6FAFD;
}

.safety-wedding .safety-box-wrap .safety-box .text-area h2 {
  padding-top: 3em;
  font-weight: bold;
  color: #2A4691;
}

@media screen and (max-width: 640px) {
  .safety-wedding .safety-box-wrap .safety-box .text-area h2 {
    font-size: 16px;
  }
}

@media screen and (min-width: 639px) {
  .safety-wedding .safety-box-wrap .safety-box .text-area h2 {
    font-size: 20px;
  }
}

.safety-wedding .safety-box-wrap .safety-box .text-area p {
  margin-top: 1em;
  line-height: 2;
  text-align: left;
}

.safety-wedding .mataniti-wedding {
  max-width: 980px;
  width: 90%;
  border: double 4px #AE9269;
  margin: 4em auto 0 auto;
  padding: 2em 1em;
}

@media screen and (max-width: 640px) {
  .safety-wedding .mataniti-wedding {
    font-size: 14px;
  }
}

.safety-wedding .mataniti-wedding .text {
  line-height: 2;
}

@media screen and (max-width: 640px) {
  .safety-wedding .mataniti-wedding .text {
    font-size: 14px;
  }
}

@media screen and (min-width: 639px) {
  .safety-wedding .mataniti-wedding .text {
    font-size: 16px;
  }
}

.safety-wedding .mataniti-wedding .button {
  margin-top: 1.5em;
}

.safety-wedding .mataniti-wedding .button a {
  position: relative;
  display: inline-block;
  padding: 1.25em 2em;
  background-color: #AE9269;
  line-height: 1.4;
  text-align: center;
  text-decoration: none;
  color: #fff;
}

@media screen and (max-width: 640px) {
  .safety-wedding .mataniti-wedding .button a {
    font-size: 15px;
  }
}

@media screen and (min-width: 639px) {
  .safety-wedding .mataniti-wedding .button a {
    font-size: 17px;
  }
}

.safety-wedding .mataniti-wedding .button a i {
  display: inline-block;
  width: 25px;
  height: 5px;
  margin-bottom: 0.25em;
  margin-left: 1em;
  border-bottom: 1px solid #fff;
  border-right: 2px solid #fff;
  transform: skew(45deg);
}

._bnrArea {
  padding: 0px 0 55px;
}

@media screen and (max-width: 640px) {
  ._bnrArea {
    padding: 12% 0 12%;
  }
}

._bnrArea ul {
  margin: 0 auto;
  width: 90%;
  max-width: 910px;
}

._bnrArea ul li {
  margin-bottom: 35px;
}

@media screen and (max-width: 640px) {
  ._bnrArea ul li {
    margin-bottom: 3%;
  }
}

._bnrArea ul li a {
  display: block;
}

._bnrArea ul li a img {
  width: auto;
  height: 215px;
}

@media screen and (max-width: 640px) {
  ._bnrArea ul li a img {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 640px) {
  ._bnrArea ul li a img.sp-non {
    display: none;
  }
}

@media screen and (min-width: 639px) {
  ._bnrArea ul li a img.pc-non {
    display: none;
  }
}

.mvWrap {
  position: relative;
}

@media screen and (max-width: 1280px) and (max-height: 640px) {
  .mvWrap .mkvbanner {
    bottom: calc(3.5vw + 100px);
  }
}

footer .nav_wrap nav.global a {
  padding: 0 20px !important;
}

main section.sec11 .content .flexwrap {
  flex-wrap: wrap !important;
  justify-content: center !important;
  gap: calc((1100px - 530px - 530px) / 2);
}

@media screen and (max-width: 640px) {
  main section.sec11 .content .flexwrap a.box {
    width: 47% !important;
  }
}

/* 20220913 add task214 */
.renewalTopic {
  margin: 5em 0;
  padding: 5em 0;
  background-color: #FCF3D6;
}

@media screen and (max-width: 640px) {
  .renewalTopic {
    margin: 3em 0;
    padding: 3em 0;
  }
}

.renewalTopic figure {
  margin: 0;
}

.renewalTopic .inner {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
}

@media screen and (max-width: 640px) {
  .renewalTopic .inner {
    max-width: none;
    width: 100%;
    flex-direction: column-reverse;
  }
}

.renewalTopic .imgBox {
  position: relative;
  z-index: 5;
  margin-right: -3%;
  flex-shrink: 0;
  width: 60%;
}

@media screen and (max-width: 640px) {
  .renewalTopic .imgBox {
    z-index: auto;
    margin-right: 0;
    width: 90%;
  }
}

.renewalTopic .imgBox img {
  display: block;
  width: 100%;
}

.renewalTopic .imgBox span {
  position: absolute;
  bottom: 5px;
  right: 5px;
  font-weight: bold;
  text-shadow: 1px 0 0 white, 0 1px 0 white, -1px 0 0 white, 0 -1px 0 white;
}

.renewalTopic .textBox {
  position: relative;
  padding: 5.5vw 2vw 5.5vw 3vw;
  width: 40%;
  background-color: #fff;
}

@media screen and (max-width: 640px) {
  .renewalTopic .textBox {
    padding: 3em;
    width: 60%;
  }
}

.renewalTopic .textBox .enTitle {
  position: absolute;
  top: -8%;
  right: -13%;
  margin: 0;
  width: 80%;
}

@media screen and (max-width: 640px) {
  .renewalTopic .textBox .enTitle {
    width: 80%;
  }
}

.renewalTopic .textBox .enTitle img {
  display: block;
  width: 100%;
}

.renewalTopic .textBox .sectionTitle img {
  margin: 0 auto;
  display: block;
  width: 70%;
  max-width: 355px;
}

@media screen and (max-width: 640px) {
  .renewalTopic .textBox .sectionTitle img {
    width: 100%;
  }
}

.renewalTopic .textBox h2 {
  margin-top: 1.5vw;
  font-size: 20px;
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .renewalTopic .textBox h2 {
    margin-top: 1.5em;
  }
}

.renewalTopic .textBox p {
  margin: 1.5vw 0;
}

@media screen and (max-width: 640px) {
  .renewalTopic .textBox p {
    display: none;
  }
}

.renewalTopic .textBox a {
  padding: 1vw 3.5vw;
  display: inline-block;
  text-align: center;
  color: #666;
  border: 1px solid #666;
}

@media screen and (max-width: 640px) {
  .renewalTopic .textBox a {
    margin-top: 1.5em;
    padding: 1em 0;
    width: 100%;
    width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .mkvbanner._sp._bottom {
    top: auto;
    bottom: 30vw;
  }
}

@media screen and (max-width: 640px) {
  #main-contents header .top_header .sp .bx-wrapper .bx-viewport .bxslider li .mkvbanner._sp img {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 640px) {
  .renewalTopic .textBox._ttl2 {
    padding: 2.5em;
  }
}

.renewalTopic .textBox._ttl2 .enTitle {
  position: static;
  margin: 0 auto;
  width: 70%;
}

@media screen and (max-width: 640px) {
  .renewalTopic .textBox._ttl2 .enTitle {
    width: 100%;
  }
}

.renewalTopic .textBox._ttl2 h2 {
  color: #2a4691;
  margin-top: 0.5vw;
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .renewalTopic .textBox._ttl2 h2 {
    margin-top: 1.5vw;
    font-size: 4vw;
  }
}

.renewalTopic .textBox._ttl2 p {
  margin-bottom: 0;
}

@media screen and (max-width: 640px) {
  .renewalTopic .textBox._ttl2 p {
    margin-top: 6vw;
    display: block;
    font-size: 14px;
  }
}

main section.sec1 .bg_wrap._concept2 {
  background-image: url(../../img/top/bg_sec1_2.jpg);
  height: 750px;
}

@media screen and (max-width: 640px) {
  main section.sec1 .bg_wrap._concept2 {
    background-image: url(../../img/top/bg_sec1_2_sp.jpg);
    height: 80vh;
  }
}

@media screen and (max-width: 640px) {
  main section.sec1 .bg_wrap._concept2 .textwrap {
    padding-top: 5vh;
  }
}

main section.sec1 .bg_wrap._concept2 .textwrap p {
  line-height: 1.7;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}

@media screen and (max-width: 640px) {
  main section.sec10 .content .party_slider .slider p.title {
    text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    /* Safari */
    font-size: 14px !important;
  }
}

.ppBnr {
  text-align: center;
}

.ppBnr a {
  display: inline-block;
  max-width: 900px;
  width: 90%;
}

.ppBnr a img {
  max-width: 100%;
}
